import Image from 'next/image';
import Link from 'next/link';

import { customLoader } from '../../utils/customLoaderImage';

import style from './404.module.scss';

export default function Index() {
  return (
    <div className={style.section}>
      <h1 className={style.title}>OPS!</h1>
      <h2>Parece que algo deu errado!</h2>
      {' '}
      <p className={style.texto404}>
        A página que você procura não existe
        <br />
        ou está em manutenção
      </p>
      <Link
        href='/'
        passHref>
        <button
          aria-label='Voltar para a página inicial'
          type='button'>
          <Image
            src='/img/setavermais.svg'
            loader={customLoader}
            alt='brands icon'
            width={25}
            height={25}
            className={style.seta}
          />
          Voltar para o Início
        </button>
      </Link>
    </div>
  );
}
